import { Component, Input } from '@angular/core';

import { v4 as uuidv4 } from 'uuid';

import { IRuleGroup, IRuleResult, RuleStrictness } from '../../../model/rule-engine';
import { RuleComplianceOverride } from '../../../rule-compliance/models';

@Component({
  selector: 'app-panel-checklist-rule',
  templateUrl: './panel-checklist-rule.component.html',
  styleUrls: ['./panel-checklist-rule.component.less']
})
export class PanelChecklistRuleComponent {
  @Input() rule: IRuleResult;
  @Input() ruleGroup?: IRuleGroup;
  @Input() styleAsHeading: boolean = false;
  @Input() ruleComplianceOverride?: RuleComplianceOverride;

  readonly ruleStrictness = RuleStrictness;

  readonly tooltipId = `ruleinfo-${uuidv4()}`;
}
